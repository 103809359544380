import { Box, Card, Link, Typography } from '@oresundsbron/bridge-ui';
import { ArrowUp } from '@oresundsbron/icons';
import { FC, ReactNode } from 'react';

interface DashboardCardProps {
  icon: ReactNode;
  title: string;
  preamble: string;
  href: string;
}

const DashboardCard: FC<DashboardCardProps> = ({
  icon,
  title,
  preamble,
  href,
}) => (
  <Card.Root
    className="relative flex h-full items-center shadow-2xl"
    shadow={false}
  >
    <Card.Content className="flex h-full w-full items-center gap-4 md:flex-col md:text-center">
      <Box aria-hidden="true">{icon}</Box>
      <Box className="flex w-full items-center justify-between">
        <Box className="flex h-full w-full flex-col justify-center gap-2 md:gap-4 md:pl-0">
          <Typography as="h3" intent="headline" size="sm">
            <Link full color="inherit" className="text-neutral-900" href={href}>
              {title}
            </Link>
          </Typography>
          <Typography as="p" intent="label">
            {preamble}
          </Typography>
        </Box>
        <Box className="pl-3 md:hidden">
          <ArrowUp className="rotate-90 text-xl" />
        </Box>
      </Box>
    </Card.Content>
  </Card.Root>
);

export default DashboardCard;
